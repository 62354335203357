// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_tk d_gt d_cr";
export var heroHeaderCenter = "v_gv d_gv d_cr d_dv";
export var heroHeaderRight = "v_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "v_tl d_gq d_cv";
export var heroParagraphCenter = "v_gr d_gr d_cv d_dv";
export var heroParagraphRight = "v_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "v_tm d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "v_tn d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "v_tp d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "v_tq d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "v_tr d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "v_rH q_rH";
export var heroExceptionNormal = "v_rJ q_rJ";
export var heroExceptionLarge = "v_rK q_rK";
export var Title1Small = "v_rm q_rm q_qS q_qT";
export var Title1Normal = "v_rn q_rn q_qS q_qV";
export var Title1Large = "v_rp q_rp q_qS q_qW";
export var BodySmall = "v_rB q_rB q_qS q_q9";
export var BodyNormal = "v_rC q_rC q_qS q_rb";
export var BodyLarge = "v_rD q_rD q_qS q_rc";