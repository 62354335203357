// extracted by mini-css-extract-plugin
export var articleWrapper = "m_pF";
export var articleText = "m_pG d_dt d_cr";
export var header = "m_pH d_Y d_v";
export var headerImageWrapper = "m_pJ d_bc d_Z d_7 d_6 d_3 d_8 d_bj d_bQ";
export var headerGradient = "m_pK d_bc d_Z d_7 d_6 d_3 d_8";
export var headerGradientOverlay = "m_pL d_bc d_Z d_7 d_6 d_3 d_8";
export var headerContentWrapper = "m_pM d_by d_bN d_bC d_bH d_bk d_Y";
export var headerContentWrapperAlt = "m_pN d_by d_bN d_bC d_bH d_bk d_Y";
export var contentWrapper = "m_pP d_v";
export var dateTag = "m_pQ d_cd d_cq d_dp d_v";
export var icon1 = "m_pR d_cN";
export var icon2 = "m_pS d_cN d_cH";
export var tagBtn = "m_pT d_b0 d_cC d_dn d_dz";
export var headerText = "m_pV d_v d_cd d_cr d_bV";
export var center = "m_pW d_dv";
export var videoIframeStyle = "m_pX d_d4 d_v d_G d_bx d_b0 d_Q";
export var articleImageWrapper = "m_pY d_cr d_Y";
export var articleImageWrapperIcon = "m_pZ d_cr d_Y";
export var articleRow = "m_p0 d_bC";
export var quoteWrapper = "m_gP d_bB d_bN d_cr";
export var quoteBar = "m_p1 d_G";
export var quoteText = "m_p2";
export var authorBox = "m_p3 d_v";
export var authorRow = "m_p4 d_bC d_bV d_cx";
export var separator = "m_p5 d_v";
export var line = "m_fj d_v d_fj d_cX d_cx";
export var authorImage = "m_ln d_b6 d_Y d_v d_cs";
export var authorText = "m_p6 d_cg d_cs";
export var masonryImageWrapper = "m_p7";
export var bottomSeparator = "m_p8 d_v d_cx";
export var linksWrapper = "m_p9 d_cg d_dt";
export var comments = "m_qb d_bC d_cx";
export var sharing = "m_qc d_by d_bC d_bH";
export var shareText = "m_qd d_v d_dv";
export var icon = "m_qf";
export var customRow = "m_qg d_bC d_Y";
export var text = "m_n8";
export var SubtitleSmall = "m_qh";
export var SubtitleNormal = "m_qj";
export var SubtitleLarge = "m_qk";