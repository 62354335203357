// extracted by mini-css-extract-plugin
export var alignDiscLeft = "t_sN d_fn d_bF d_dt";
export var alignLeft = "t_pn d_fn d_bF d_dt";
export var alignDiscCenter = "t_sP d_fp d_bC d_dv";
export var alignCenter = "t_bN d_fp d_bC d_dv";
export var alignDiscRight = "t_sQ d_fq d_bG d_dw";
export var alignRight = "t_pp d_fq d_bG d_dw";
export var footerContainer = "t_sR d_dV d_bV";
export var footerContainerFull = "t_sS d_dS d_bV";
export var footerHeader = "t_kd d_kd";
export var footerTextWrapper = "t_sT d_v";
export var footerDisclaimerWrapper = "t_kl d_kl d_cj";
export var badgeWrapper = "t_sV d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "t_sW undefined";
export var footerDisclaimerLeft = "t_sX undefined";
export var verticalTop = "t_sY d_by d_bH d_bM d_bK";
export var firstWide = "t_sZ";
export var disclaimer = "t_s0 d_by d_bH";
export var socialDisclaimer = "t_s1";
export var left = "t_s2";
export var wide = "t_s3 d_cx";
export var right = "t_s4 d_bJ";
export var line = "t_fj d_fk d_ct";
export var badgeDisclaimer = "t_s5 d_bB d_bN d_bH";
export var badgeContainer = "t_s6 d_by d_bG d_bN";
export var badge = "t_s7";
export var padding = "t_s8 d_c6";
export var end = "t_s9 d_bG";
export var linkWrapper = "t_tb d_dz";
export var link = "t_mB d_dz";
export var colWrapper = "t_tc d_cw";
export var consent = "t_f d_f d_bB d_bN";
export var media = "t_td d_bw d_dx";
export var itemRight = "t_tf";
export var itemLeft = "t_tg";
export var itemCenter = "t_th";
export var exceptionWeight = "t_tj q_rj";