// extracted by mini-css-extract-plugin
export var lbContainerOuter = "C_vS";
export var lbContainerInner = "C_vT";
export var movingForwards = "C_vV";
export var movingForwardsOther = "C_vW";
export var movingBackwards = "C_vX";
export var movingBackwardsOther = "C_vY";
export var lbImage = "C_vZ";
export var lbOtherImage = "C_v0";
export var prevButton = "C_v1";
export var nextButton = "C_v2";
export var closing = "C_v3";
export var appear = "C_v4";