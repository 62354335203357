// extracted by mini-css-extract-plugin
export var campaignContainer = "k_n1 d_dV";
export var campaignContainerFull = "k_n2 d_dS";
export var wrapper = "k_n3 d_v";
export var leftWrapper = "k_n4 d_by d_bM";
export var rightWrapper = "k_n5";
export var subtitle = "k_n6";
export var title = "k_n7";
export var text = "k_n8 d_cr";
export var inlineWrapper = "k_n9 d_bB d_bF d_v";
export var btnsWrapper = "k_pb k_n9 d_bB d_bF d_v d_bH";
export var priceWrapper = "k_pc k_n9 d_bB d_bF d_v d_cg d_bN";
export var priceHeading = "k_pd";
export var disclaimerWrapper = "k_pf d_cC";
export var mediaSlot = "k_pg";
export var listWrapper = "k_ph d_bB d_bN d_v";
export var btnWrapper = "k_d1";
export var imageWrapper = "k_pj d_ff d_Y";
export var SmallSmall = "k_pk q_pk q_qS q_rd";
export var SmallNormal = "k_pl q_pl q_qS q_rf";
export var SmallLarge = "k_pm q_pm q_qS q_rb";