// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "z_fP d_fP d_by d_bG d_bH";
export var navbarDividedRight = "z_fQ d_fQ d_by d_bH";
export var menuDesign6 = "z_tG d_fL d_by d_dv d_bN d_bH";
export var menuDesign7 = "z_tH d_fL d_by d_dv d_bN d_bH";
export var menuRight = "z_tJ d_fL d_by d_dv d_bN d_bH";
export var menuLeft = "z_tK d_fL d_by d_dv d_bN d_bH";
export var menuCenter = "z_tL d_fM d_fL d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "z_qr d_fM d_fL d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "z_qC d_fN d_fL d_by d_dv d_bN d_bH";
export var isBurger = "z_tM";
export var navbarItem = "z_qs d_bw";
export var navbarLogoItemWrapper = "z_fX d_fX d_bB d_bN";
export var burgerToggleVisibleOpen = "z_tN d_gc d_bx d_Y d_br";
export var burgerToggleVisible = "z_tP d_gc d_bx d_Y d_br";
export var burgerToggle = "z_tQ d_gc d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "z_tR d_gc d_bx d_Y d_br";
export var burgerInput = "z_tS d_f1 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "z_tT d_f2 d_v d_G";
export var burgerLine = "z_tV d_f0";
export var burgerMenuDesign6 = "z_tW d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "z_tX d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "z_tY d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "z_tZ d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "z_t0 d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "z_t1 d_f8 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "z_t2 d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var secondary = "z_t3 d_bB d_bN";
export var staticBurger = "z_t4";
export var menu = "z_t5";
export var navbarDividedLogo = "z_t6";
export var nav = "z_t7";