// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "w_ts d_bC";
export var storyRowWrapper = "w_hw d_hw d_bJ";
export var storyLeftWrapper = "w_tt d_by d_bN";
export var storyWrapperFull = "w_tv d_cC";
export var storyWrapperFullLeft = "w_nM d_cC d_by d_bN d_bC";
export var contentWrapper = "w_pP d_hx";
export var storyLeftWrapperCenter = "w_tw d_hy d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "w_tx d_hD";
export var storyHeader = "w_ty d_hC d_v d_cr";
export var storyHeaderCenter = "w_hB d_hB d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "w_hz d_hz d_bx d_dv";
export var storyBtnWrapper = "w_tz d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "w_hH d_hH d_d0 d_v d_by d_bC";
export var imageWrapper = "w_pj d_ff d_Y";
export var imageWrapperFull = "w_st d_v d_G d_bd d_Y";